<template>
  <div class="main">
    <a-form-model id="formLogin" ref="form" class="user-layout-login" :model="form" :rules="rules">
      <a-alert
        v-if="isLoginError"
        type="error"
        showIcon
        style="margin-bottom: 24px;"
        :message="loginErrorInfo"
        closable
        :after-close="handleCloseLoginError" />
      <a-form-model-item prop="username" style="margin-bottom:2.4vh;">
        <a-input v-model="form.username" size="large" placeholder="请输入手机号或邮箱">
        </a-input>
      </a-form-model-item>
      <a-form-model-item prop="code" style="margin-bottom:2.4vh;">
        <a-input v-model="form.code" size="large" type="text" autocomplete="off" placeholder="验证码">
          <a-button
            slot="suffix"
            size="large"
            type="link"
            htmlType="submit"
            class="login-button login-buttonCode font16R"
            :loading="codeLoading"
            :disabled="codeLoading"
            @click="handleSendCode">{{ beginning?time+'s后重新获取':'获取验证码' }}</a-button>
        </a-input>
      </a-form-model-item>
      <a-form-model-item prop="password" style="margin-bottom:2.4vh;">
        <a-input-password v-model="form.password" size="large" placeholder="请输入密码">
        </a-input-password>
      </a-form-model-item>
      <a-form-model-item prop="confirmPassword" style="margin-bottom:0;">
        <a-input-password v-model="form.confirmPassword" size="large" placeholder="请再次输入密码">
        </a-input-password>
      </a-form-model-item>
      <a-form-item style="margin-top:2.8vh;margin-bottom:0;">
        <a-button
          size="large"
          type="primary"
          htmlType="submit"
          class="login-button login-buttonSub font18R"
          :loading="logining"
          :disabled="logining"
          @click="handleSubmit">确认</a-button>
      </a-form-item>

    </a-form-model>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
// import { timeFix } from '@/utils/util'
// import { getCodeImg } from '@/api/login'
import { LOGIN_USERNAME, LOGIN_PASSWORD, LOGIN_REMEMBERME } from '@/store/mutation-types'
import storage from 'store'
import defaultSettings from '@/config/defaultSettings'
// import router from '@/router'
import { validEmail, validPhone } from '@/utils/validate'
import { getCalidCode, resetPassword } from '@/api/login'

export default {
  components: {
  },
  data() {
    const validatorUserName = (rule, value, callback) => {
      if (value) {
        if (!validEmail(value) && !validPhone(value)) {
          callback(new Error('请输入正确的手机号或邮箱'))
        } else {
          callback()
        }
      } else {
        callback(new Error('请输入手机号或邮箱'))
      }
    }

    const validatorConfirmPassword = (rule, value, callback) => {
      if (value) {
        if (this.form.password !== value) {
          callback(new Error('两次密码不一致'))
        } else {
          callback()
        }
      } else {
        callback(new Error('请再次输入密码'))
      }
    }
    return {
      defaultSettings,
      codeUrl: '',
      isLoginError: false,
      loginErrorInfo: '',
      form: {
        username: '',
        password: '',
        confirmPassword: '',
        code: undefined,
        uuid: '',
        rememberMe: false
      },
      rules: {
        username: [{ required: true, validator: validatorUserName, trigger: 'blur' }],
        confirmPassword: [{ required: true, validator: validatorConfirmPassword, trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
        code: [{ required: true, message: '请输入验证码', trigger: 'blur' }]
      },
      logining: false,
      captchaEnabled: false,
      redirect: undefined,

      codeLoading: false, // 获取验证码
      validCodeReqNo: null, // 验证码请求号
      beginning: false, // 是否发送验证码
      time: 60, // 倒计时
      timeInt: null // 倒计时实例
    }
  },
  watch: {
    $route: {
      handler: function (route) {
        this.redirect = route.query && route.query.redirect
      },
      immediate: true
    }
  },
  created() {
    this.getStorage()
    // if (this.captchaEnabled) {
    //   this.getCode()
    // }
  },
  methods: {
    ...mapActions(['Login', 'Logout']),
    getCode(captchaEnabled) {
      this.captchaEnabled = captchaEnabled
      // getCodeImg().then(res => {
      //   this.captchaEnabled = res.captchaEnabled === undefined ? true : res.captchaEnabled
      //   if (this.captchaEnabled) {
      //     this.codeUrl = 'data:image/gif;base64,' + res.img
      //     this.form.uuid = res.uuid
      //   }
      // })
    },
    getStorage() {
      const username = storage.get(LOGIN_USERNAME)
      const password = storage.get(LOGIN_PASSWORD)
      const rememberMe = storage.get(LOGIN_REMEMBERME)
      if (username) {
        this.form = {
          username: username,
          password: password,
          rememberMe: rememberMe
        }
      }
    },
    rememberMe(e) {
      this.form.rememberMe = e.target.checked
    },
    handleSubmit() {
      this.logining = true
      // console.log('>>>>>', this.$refs.form)

      this.$refs.form.validate(valid => {
        // console.log('>>>>1>>>', valid)

        if (valid) {
          resetPassword({
            'confirmPassword': this.form.confirmPassword,
            'emailOrMobile': this.form.username,
            'password': this.form.password,
            'validCode': this.form.code,
            'validCodeReqNo': this.validCodeReqNo
          }).then(res => {
            this.loginSuccess(res)
          }).finally((err) => {
            // console.log('err', err)
            this.loading = false
            setTimeout(() => {
              this.logining = false
            }, 1000)
          })
        } else {
          setTimeout(() => {
            this.logining = false
          }, 600)
        }
      })
    },
    /**
     * @desc 获取验证码
     * @param {  }
     * @version: 1.0.0
     */
     handleSendCode() {
      // 如果有倒计时，return false
      if (this.beginning) {
        return
      }
      this.codeLoading = true
      this.$refs.form.validateField(['username'], valid => {
        // console.log('>>>>1>>>', valid)

        if (!valid) {
          getCalidCode({
            emailOrMobile: this.form.username
          }).then(res => {
            // console.log('获取验证码>>>>', res)
            this.codeLoading = false
            this.validCodeReqNo = res.data
            this.beginning = true
            // 清除倒计时,防抖作用
            if (this.timeInt) {
              clearInterval(this.timeInt)
            }
            this.timeInt = setInterval(() => {
              if (this.time === 1) {
                // 倒计时结束就清楚这个倒计时
                clearInterval(this.timeInt)
                this.time = 60 // 倒计时60s
                this.beginning = false // 是否显示倒计时改为false
                return
              }
              this.time--
            }, 1000)
          }).finally(() => {
            this.codeLoading = false
          })
        } else {
          this.codeLoading = false
        }
      })
    },
    loginSuccess(res) {
      this.$router.push({ path: '/login' })
      // 延迟 1 秒显示欢迎信息
      setTimeout(() => {
        this.$notification.success({
          message: '重置密码成功',
          description: `请重新登录`
        })
      }, 1000)
      this.handleCloseLoginError()
    },
    requestFailed(err) {
      this.isLoginError = true
      this.loginErrorInfo = err
      this.form.code = undefined
      if (this.captchaEnabled) {
        this.getCode()
      }
    },
    handleCloseLoginError() {
      this.isLoginError = false
      this.loginErrorInfo = ''
    },
    // 打开忘记密码
    openResctPasword() {

    }
  }
}
</script>

<style lang="less" scoped>
.main {
  margin: 0 !important;

  /deep/.ant-input {
    height: 4.8vh;
    padding: 0 1.3vh;
    border: 1px solid #E4E4E5;
    font-size: 16px;
    font-family: '思源-Regular';
    border-radius: 10px;
    &:focus {
      border-color:#E4E4E5;
      box-shadow:none;
    }
  }
}

.wjmmBtn {
  display: flex;
  justify-content: flex-end;
  margin-top: 1.6vh;

  span {
    color: #999999;
    cursor: pointer;
  }
}
// .main{
//   width: 1920px;
//   height: 1080px;
//   overflow: hidden;
//   background:#d9e2ef url('./img/br.png') no-repeat 100% 46px;

//   &.loginpage-6 {
//     background: url('./img/bg-1.png') no-repeat 100% 100%;
//   }
// }
.user-layout-login {

  label {
    font-size: 14px;
  }

  .getCaptcha {
    display: block;
    width: 100%;
    height: 40px;
  }

  button.login-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 4.8vh;
    color: #2878FF;

    &.login-buttonCode {
      padding: 0;
    }

    &.login-buttonSub {
      color: #FFFFFF;
      background: #2878FF;
      border-radius: 26px 26px 26px 26px;
    }
  }

  .user-login-other {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    // text-align: left;
    margin-top: 4.1vh;
    padding-bottom: 4.1vh;
    // line-height: 22px;

    // .register {
    //   float: right;
    // }

    .user-login-other-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-bottom: 2.2vh;

      .b {
        width: 9.8vw;
        height: 0px;
        opacity: 1;
        border: 1px solid #EEEFF1;
      }

      span {
        color: #999999;
      }

    }

    .other-type {

      .other-type-icon {
        width: 3.7vh;
        height:3.7vh;
        margin-right: 1.875vw;
        cursor: pointer;

        &:last-child {
          margin: 0;
        }
      }

    }
  }

  :deep .ant-input-affix-wrapper .ant-input:not(:first-child) {
    padding-left: 40px;
    background: #F7F7F7;
  }

  // :deep .ant-input-affix-wrapper {
  //   background: #F7F7F7;
  // }
}

</style>
